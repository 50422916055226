import { stringAleatorio } from './helpers'
import store from '../store/index'

const createPayloadRequestBooking = ( booking, contractSelected, owner ) => {
    const hotelData = store.state.market.hotels.find((hotel) => hotel.id === contractSelected?.resortsid  )

    const payloadBooking =  {
        ...booking,
        dateIn:booking.checkIn,
        dateOut:booking.checkOut,
        needAuthorization: false,
        reasonAuthorization: "",
        blackout: 0, // validar esto

        vendorcode : 'TFCOWNERS', // vendor code propio
        owner: {...owner},  // tomo el objeto owner con el que estamos trabajando
        promotionCode: "DROP 100",
        children: booking.numberChildren,
        highSeason:  null,
        adults: booking.numberAdults,
        resortId: contractSelected?.resortsid || null,
        idHotel: contractSelected?.resortsid || null,
        hotel: hotelData.dingusCode, //dingus code
        occupanttype: 1,
        idioma: store.state.auth.user.languageName === 'English' ? 'en' : 'es',
        isManualRate: false,
        manualRate: "",
        isguest: false,
        origin: 1,
        rate: "",
        rateplanid: "",
        roomCode: "",
        tarifa: "",
        // '3BST' //  lo tomo del contracto o tambien desde el owner
        roomType: contractSelected?.categoryroomName || null,
        idContract: contractSelected?.id || null,
        categoryroomId: contractSelected?.categoryroomId || null,
        isWeb: true,
        idroomsConfiguration: booking.idroomsConfiguration,
    }

    return payloadBooking
}
const formatBookingToEdit = (booking) => {
    if (booking) {
        const { id, datein, dateout, notes, isguest, occupanttype,nights, adults, children, namesbeneficiaries, idroomsConfiguration, status } = booking

        const adultsGuests = []
        const childsGuests = []

        namesbeneficiaries.forEach(guest => guest.adult == true || !guest.edad ? adultsGuests.push({...guest, adult: guest.adult, key: stringAleatorio()}) : childsGuests.push({...guest, adult: guest.adult, key: stringAleatorio()}))

        return {
            idBooking: id,
            checkIn: datein || "",
            checkInCheckOut: Boolean(datein) && Boolean(dateout) ? `${datein} to ${dateout}` : "", //rango de fechas
            originalCheckInCheckOut: Boolean(datein) && Boolean(dateout) ? `${datein} to ${dateout}` : "", //rango de fechas
            checkOut: dateout || "",
            comments: notes || "",
            isAGuest: occupanttype === '2' || false,
            nights: nights || 0,
            numberAdults: adults || 1,
            numberChildren: children || 0,
            adultsDetail: adultsGuests,
            dataChildrens: childsGuests,
            idroomsConfiguration: idroomsConfiguration,
            status: parseInt(status)
        }
    } else return null
}
const createPayloadBooking = (booking, contractSelected) => {
    const { idBooking, dataChildrens, checkIn, checkOut, comments, nights, numberAdults, numberChildren, isAGuest, adultsDetail } = booking
    const { housingid } = contractSelected
    const infoBeneficiaries = [...adultsDetail, ...dataChildrens] // operador spread :o
    const payload = {
        adults: numberAdults,
        children: numberChildren,
        createdBy:store.state.auth.userAdmin ? store.state.auth.userAdmin.idUser : store.state.auth.user.idOwner ,
        dateIn: checkIn,
        dateOut: checkOut,
        housing: housingid,
        id: Boolean(idBooking) ? idBooking : 0,
        infoBeneficiaries,
        isguest: isAGuest,
        needAuthorization: false,
        nights,
        notes: comments,
        occupanttype: !isAGuest ? 1 : 2, //si es propietario 1, sino 2
        origin: 1,
        owner: store.state.auth.user.idOwner,
        reservetype: 1,
        status: 1,
        userId: store.state.auth.userAdmin ? store.state.auth.userAdmin.idUser : 116
    };
    return payload
}

const createPayloadModifyBooking = (booking, room, contractSelected) => {
    const { idBooking, dataChildrens, checkIn, checkOut, comments, nights, numberAdults, numberChildren, isAGuest, adultsDetail, isEdit, originalCheckInCheckOut, checkInCheckOut } = booking
    const { RoomImg, RoomTypeName, detailSelected, priceInit } = room
    const { MealPlanCodes, PromotionCode, RatePlanID, RatePlanCode, RoomTypeCode } = detailSelected

    const mainGuest = adultsDetail?.find(adult=>adult.ismain == true) || adultsDetail[0]

    let payload = {
        adults: numberAdults,
        numberChildren,
        email: mainGuest?.email,
        name: mainGuest?.name,
        lastname: mainGuest?.lastname,
        phone: mainGuest?.phone,
        nights,
        ninos: dataChildrens,
        id: idBooking,
        isEditBooking: originalCheckInCheckOut == checkInCheckOut,
    }

    if (originalCheckInCheckOut != checkInCheckOut) {
        payload.resortId = contractSelected?.resortsid || null,
        payload.img = RoomImg,
        payload.idioma = store.state.auth.user.languageName === 'English' ? 'en' : 'es',
        payload.dateIn = checkIn,
        payload.dateOut = checkOut,
        payload.vendorcode = "TFCOWNERS",
        payload.PromotionCode = PromotionCode,
        payload.RoomTypeCode = RoomTypeCode,
        payload.roomtypename = RoomTypeName,
        payload.MealPlanCodes = MealPlanCodes,
        payload.RatePlanID = RatePlanID,
        payload.RatePlanCode = RatePlanCode,
        payload.rateInit = priceInit,
        payload.term = { id: 8, title: "Política Propietarios" },
        payload.guestReserved = isAGuest
    }

    return payload
}

const formatRooms = (rooms) => {
    rooms.forEach(room => {
        room.uuid = stringAleatorio()
        // room.rates = room.rates.filter(rate => rate.MealPlanCodes === 'EPSOC')
        room.detailSelected = room.rates.length ? room.rates[0] : [] // detail selected obtiene el
        room.priceInit = room.rates.length ? room.rates[0].AmountAfterTax : '0.00'
    })
    return rooms
}

export {
    createPayloadRequestBooking,
    formatBookingToEdit,
    createPayloadBooking,
    formatRooms,
    createPayloadModifyBooking
}