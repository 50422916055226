<template>
    <div>             
        <div v-if="!isEditing">                                 
            <div class="container-tables">
                <div class="column-table" v-if="benefitsShowReadOnlyModeGroupOne.length > 0">
                    <div class="d-flex justify-content-between align-items-center mb-1">
                        <div>                            
                            <h6 class="subtitle font-weight-bolder">
                                {{ $t("Benefits")   }} {{  yearsBenefitsList[0] }}
                            </h6>
                        </div>
                        <div class="d-flex flex-column align-items-center">
                            <div
                                v-if="statusBenefits.valueStatus === '1'"                                                                
                            >
                                {{ $t('Benefits require authorization') }}
                            </div>
                            <div
                                v-if="statusBenefits.valueStatus === '2'"                                                                
                            >
                                {{ $t('the benefits are authorized') }}
                            </div>
                            <div
                                v-if="statusBenefits.valueStatus === '3'"                                                                
                            >
                                {{ $t('benefits not authorized') }}
                            </div>
                   
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-sm">
                            <thead>
                                <tr>                                
                                    <th> {{$t('benefit name')}} </th>												
                                    <th class="centerT">{{ $t('Balance') }} </th>
                                    <th class="centerT">
                                        {{                                             
                                            statusBenefits.valueStatus === '2' ? $t("authorized quantity") 
                                            : statusBenefits.valueStatus === '3' ? $t("unauthorized  quantity") 
                                            : $t("quantity to authorize")
                                         }}
                                    </th>
                                </tr>
                            </thead>
                            
                            <tbody>																                             
                                <tr v-for="(benefit, index) in benefitsShowReadOnlyModeGroupOne" :key="index">                            
                                    <td >
                                        {{ nameBenefit( benefit ) }}
                                    </td>
                                
                                    <td class="centered-cell">
                                        {{ benefit.balance }}
                                    </td>
                                    <td class="centered-cell">                        
                                        <span >
                                            {{benefit.qty}}                                        
                                        </span>                                                    
                                    </td>
                                </tr>								
                            </tbody>										
                        </table>	
                        
                    </div>
                </div>
                <div class="column-table" v-if="benefitsShowReadOnlyModeGroupTwo.length > 0">
                    <div class="d-flex justify-content-between mb-1">
                        <div>                                                        
                            <h6 class="subtitle font-weight-bolder">
                                {{ $t("Benefits")   }} {{  yearsBenefitsList[1] }} 
                            </h6>
                        </div>
                        <div class="d-flex align-items-center ">
                            <div
                                v-if="statusBenefits.valueStatus === '1'"                                
                                class="mr-1"
                            >
                                {{ $t('Benefits require authorization') }}
                            </div>
                            <div
                                v-if="statusBenefits.valueStatus === '2'"                                                                
                            >
                                {{ $t('the benefits are authorized') }}
                            </div>
                            <div
                                v-if="statusBenefits.valueStatus === '3'"                                                                
                            >
                                {{ $t('benefits not authorized') }}
                            </div>
                           
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-sm">
                            <thead>
                                <tr>                                
                                    <th> {{$t('benefit name')}} </th>												
                                    <th class="centerT">{{ $t('Balance') }} </th>
                                    <th class="centerT">
                                        {{                                             
                                            statusBenefits.valueStatus === '2' ? $t("authorized quantity") 
                                            : statusBenefits.valueStatus === '3' ? $t("unauthorized  quantity") 
                                            : $t("quantity to authorize")
                                         }}
                                    </th>
                                </tr>
                            </thead>
                            
                            <tbody>																                             
                                <tr v-for="(benefit, index) in benefitsShowReadOnlyModeGroupTwo" :key="index">                            
                                    <td >
                                        {{ nameBenefit( benefit ) }}
                                    </td>
                                
                                    <td class="centered-cell">
                                        {{ benefit.balance }}
                                    </td>
                                    <td class="centered-cell">                                 	
                                        <span >
                                            {{benefit.qty}}                                        
                                        </span>                                                
                                    </td>
                                </tr>								
                            </tbody>										
                        </table>	
                        
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isEditing" >
            <div class="container-tables" v-if="benefitsSecondGroup.length === 0">
                <div class="column-table">
                    <div class="d-flex justify-content-end align-items-center">
                        <div
                            v-if="statusBenefits.valueStatus === '1'"                                                                                                                                    
                            class="mr-1" 
                        >
                            {{ $t('Benefits require authorization') }}
                        </div>
                        <b-button
                            v-if="canEditBooking && statusBenefits.valueStatus === '1'"
                            variant="primary"
                            size="sm"
                            @click="authorizeBenefits"
                        >
                            {{ $t("Authorize") }}
                        </b-button>

                        <b-button
                            v-if="canEditBooking && statusBenefits.valueStatus === '1'"
                            class="ml-1"
                            variant="danger"
                            size="sm"
                            @click="notAuthorize"
                        >
                            {{ $t("not authorize") }}
                        </b-button>
                    </div>
                </div>
            </div>
            <b-row v-else class="mb-1">
                <b-col md="12">
                    <div class="d-flex justify-content-end align-items-center">
                        <span
                            v-if="statusBenefits.valueStatus === '1'"                                                                                                                                    
                            class="mr-1" 
                        >
                            {{ $t('Benefits require authorization') }}
                        </span>
                        <b-button
                            v-if="canEditBooking && statusBenefits.valueStatus === '1'"
                            variant="primary"
                            size="sm"
                            @click="authorizeBenefits"
                        >
                            {{ $t("Authorize") }}
                        </b-button>

                        <b-button
                            v-if="canEditBooking && statusBenefits.valueStatus === '1'"
                            class="ml-1"
                            variant="danger"
                            size="sm"
                            @click="notAuthorize"
                        >
                            {{ $t("not authorize") }}
                        </b-button>
                    </div>
                </b-col>
            </b-row>
   
            <div class="container-tables">
                <div class="column-table" v-if="benefitsFirstGroup.length > 0">
                    <div class="d-flex justify-content-between align-items-center mb-1">
                        <div>                            
                            <h6 class="subtitle font-weight-bolder">
                                {{ $t("Benefits")   }} {{  yearsBenefitsList[0] }}
                            </h6>
                        </div>
                        
                    </div>
                    <div class="table-responsive">
                        <table class="table table-sm">
                            <thead>
                                <tr>                                
                                    <th> {{$t('benefit name')}} </th>												
                                    <th class="centerT">{{ $t('Balance') }} </th>
                                    <th class="centerT">{{ $t('Quantity to authorize') }} </th>
                                </tr>
                            </thead>
                            
                            <tbody>																                             
                                <tr v-for="(benefit, index) in benefitsFirstGroup" :key="index">                            
                                    <td >
                                        {{ nameBenefit( benefit ) }}
                                    </td>
                                
                                    <td class="centered-cell">
                                        {{ benefit.balance }}
                                    </td>                            
                                    <td class="centered-cell">                                 
                                        <ValidationProvider :name="$t('Quantity')" :rules="{ max_balance: benefit.balance }" v-if="!userAdmin">
                                            <div slot-scope="{ errors }">
                                                <b-form-input			
                                                    size="sm"
                                                    type="number"
                                                    :placeholder="$t('Quantity')"				
                                                    v-model="benefit.qty"     
                                                    :state="errors.length > 0 ? false : null"                                                
                                                    style="max-width: 150px;"
                                                    @input="setQtyBenefit(benefit)"   
                                                    :disabled="!canEditBeforeCheckOut"

                                                />
                                                <b-form-invalid-feedback class="invalid-feedback-custom">{{errors[0]}}</b-form-invalid-feedback>
                                            </div>
                                        </ValidationProvider>
                                        <span v-else>
                                            {{benefit.qty}}                                        
                                        </span>           
                                    </td>
                                </tr>								
                            </tbody>										
                        </table>	
                        
                    </div>
                </div>
                <div class="column-table" v-if="benefitsSecondGroup.length > 0">
                    <div class="d-flex justify-content-between mb-1">
                        <div>                                                        
                            <h6 class="subtitle font-weight-bolder">
                                {{ $t("Benefits")   }} {{  yearsBenefitsList[1] }} 
                            </h6>
                        </div>        
                    </div>
                    <div class="table-responsive">
                        <table class="table table-sm">
                            <thead>
                                <tr>                                
                                    <th> {{$t('benefit name')}} </th>												
                                    <th class="centerT">{{ $t('Balance') }} </th>
                                    <th class="centerT">{{ $t('Quantity to authorize') }} </th>
                                </tr>
                            </thead>
                            
                            <tbody>																                             
                                <tr v-for="(benefit, index) in benefitsSecondGroup" :key="index">                            
                                    <td >
                                        {{ nameBenefit( benefit ) }}
                                    </td>
                                
                                    <td class="centered-cell">
                                        {{ benefit.balance }}
                                    </td>
                                    <td class="centered-cell">                                 
                                        <ValidationProvider :name="$t('Quantity')" :rules="{ max_balance: benefit.balance }" v-if="!userAdmin">
                                            <div slot-scope="{ errors }">
                                                <b-form-input			
                                                    size="sm"
                                                    type="number"
                                                    :placeholder="$t('Quantity')"				
                                                    v-model="benefit.qty"     
                                                    :state="errors.length > 0 ? false : null"                                                
                                                    style="max-width: 150px;"
                                                    @input="setQtyBenefit(benefit)"   
                                                    :disabled="!canEditBeforeCheckOut"

                                                />
                                                <b-form-invalid-feedback class="invalid-feedback-custom">{{errors[0]}}</b-form-invalid-feedback>
                                            </div>
                                        </ValidationProvider>
                                        <span v-else>
                                            {{benefit.qty}}                                        
                                        </span>           
                                    </td>
                                </tr>								
                            </tbody>										
                        </table>	
                        
                    </div>
                </div>
            </div>
        </div>
   
	</div>
</template>

<script>
import { extend } from 'vee-validate'
import { mapState, mapActions, mapMutations , mapGetters } from "vuex"

export default {
	props: {			
        isEditing:{
            type: Boolean,
            required: true
        },
        bookingEdit: {
			type: Object,
			default: null,
		},
        canEditBooking: {
			type: Boolean,
			default: false
		},
      
	},
    created(){
        extend('max_balance', {
            params: ['balance'],
            validate(value, { balance }) {
                return value <= balance;
            },            
            message: (fieldName, { balance }) => `${this.$t('the qty cannot be greater than balance',{field: fieldName, balance: balance })}`
        })
    },
    data() {
		return {	
            badgeStatus:{
                1: 'warning', 
                2: 'success',
                3: 'danger'
            }
		}
	},
    computed: {		        
		...mapState('auth', ['user', 'userAdmin']),  
		...mapState('owners', ['yearsBenefitsList','benefitsToUseInBooking']),	
        ...mapGetters('owners', ['benefitsShowReadOnlyModeGroupOne', 'benefitsShowReadOnlyModeGroupTwo','benefitsFirstGroup', 'benefitsSecondGroup']),       
        canEditBeforeCheckOut(){
            const currentDate = new Date().toISOString().split('T')[0]
            const givenDate = new Date(this.bookingEdit.checkOut).toISOString().split('T')[0]
            return currentDate < givenDate
        },
        areBenefitsToAuthorize(){
            const result = this.benefitsToUseInBooking.some(item => item.id !== null && item.authorizedBy === null)
            return result
        },
        statusBenefits(){
            const statuses = { 1: this.$t('pending authorization'), 2: this.$t('authorized'), 3:  this.$t('not authorized') }            
            const firstValidBenefit = this.benefitsToUseInBooking.find(item => item.id !== null && item.status )   
            if( firstValidBenefit ){
                const status = firstValidBenefit.status
                return { statusName: statuses[status] || statuses[1], valueStatus: status }
            } 
            
            return { statusName: statuses[1], valueStatus: 1}
        }
    },
	methods: {
		...mapActions('owners', ['fetchBenefitsBookingOwner', 'fetchBenefitsByYearAndContract']),		
        ...mapMutations('owners',['setQtyBenefitToUseInBooking']),		           
        setQtyBenefit( data ){
            const { transaction, qty } = data
            this.setQtyBenefitToUseInBooking({transaction, qty })
        },
        nameBenefit( benefit ){
            const idLangUser = this.user.languageId || 1          
            return idLangUser === 1 ? benefit.benefitnameEng : benefit.benefitname
        },
        authorizeBenefits(){
            this.$emit('authorize-benefits-booking')
        },
        notAuthorize(){
            this.$emit('not-authorize-benefits-booking')
        }
	},
}
</script>
<style scoped>
   .centered-cell, th.centerT {
        text-align: center; /* Centrado horizontal */
        vertical-align: middle; /* Centrado vertical */
    }

    td input {
        margin: 0 auto;
        display: block;
    }
    .container-tables {
        display: flex;
        flex-direction: column;
        gap: 1rem; 
        /* padding: 1rem; */
    }

    .column-table{
        max-width: 700px;
        width: 100%;        
    }

   
    @media(min-width: 1080px) {
        .container-tables {
            flex-direction: row;
            gap: 7rem; 
        }
        .column-table{
            flex: 1;
        }
    }

</style>