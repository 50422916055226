<template>
    <div>    
        <div  class="border p-1 mb-1" v-for="(room, index) in rooms" :key="index">
            <b-row>
                <div class="col-xs-6 col-md-5 col-sm-12">
                    <ImgRoom :room="room"/>
                </div>
                <div class="col-xs-6 col-md-7 col-sm-12">

                        <h6 class="d-flex justify-content-between mt-1">
                            <b class="text-body"> {{ room.Name }} </b>
                            <b-badge :variant="myContracts.contractSelected.categoryroomName == room.RoomTypeCode ? 'success':'warning' " v-if="myContracts" class="mb-1">
                                <feather-icon icon="AlertCircleIcon" class="mr-25" />
                                <span> {{ $t("room") }} : {{ room.RoomTypeCode }} </span>
                            </b-badge>
                        </h6>

                        <Rates :room="room" />
                        
                        <div v-if="room.listBenefits_Contract.length > 0">
                            <hr>

                            <div class="d-flex justify-content-between benefit-info">
                                <div>
                                    <p><b>{{ $t("benefit name") }} : </b> {{room.listBenefits_Contract[0].benefitCode}}</p>
                                    <p><b>{{ $t("total nights") }}: </b> {{room.listBenefits_Contract[0].total}}</p>
                                </div>
                                
                                <div>
                                    <p><b>{{ $t("available nights") }} : </b> {{room.listBenefits_Contract[0].saldo}}</p>
                                    <p > <b> {{ $t("nights to use") }} : </b> {{room.upgradesRoomsInfo}}</p>
                                </div>

                                <div><b>{{ $t("used nights") }}: </b> {{room.listBenefits_Contract[0].used}}</div>
                            </div>

                            <hr>    
                        </div>

                        <div v-if="room.listBenefits_Xtra.length > 0">
                            
                            <div class="d-flex justify-content-between benefit-info">
                                <div>
                                    <p><b>{{ $t("benefit name") }} : </b> {{room.listBenefits_Xtra[0].benefitCode}}</p>
                                    <p><b>{{ $t("total nights") }}: </b> {{room.listBenefits_Xtra[0].total}}</p>
                                </div>
                                
                                <div>
                                    <p><b>{{ $t("available nights") }} : </b> {{room.listBenefits_Xtra[0].saldo}}</p>
                                    <p > <b> {{ $t("nights to use") }} : </b> {{room.upgradesRoomsInfo}}</p>
                                </div>

                                <div><b>{{ $t("used nights") }}: </b> {{room.listBenefits_Xtra[0].used}}</div>
                            </div>

                        </div>                                            

                        <div class="bntToAddCart">
                            <b-button
                                variant="primary"                                
                                @click="book(room)" :disabled="isSearchingRooms"
                            >
                                 {{ $t("Book") }} 
                            </b-button>
                        </div>
                </div>
            </b-row>
        </div>
    </div>
</template>
<script>

import { mapState } from "vuex"

import ImgRoom from '@/modules/owners/components/reservations/ImgRoom'
import Rates from '@/modules/owners/components/reservations/Rates'
import { utils } from '@/modules/owners/mixins/utils'

export default {
	mixins:[utils],
    props:{
        rooms:{
            type: Array,
            required: true
        },
        isSearchingRooms:{
            type: Boolean,
            required: true
        }
    },
    components: {
        ImgRoom,  Rates      
    },
    computed: {
        ...mapState("auth", ["user", "myContracts"]),
    },
    methods:{
        book(room){
            // validar si es admin desde mixin en utils
            if( this.isAdmin ){
                return this.$swal.fire({icon: "error", title: `${this.$t("Restricted Access")}`, html: `${this.$t("Text Restricted Access Admin")}`});
            }
            this.$emit('set-book', room)
        },
       
    }
}
</script>

<style scoped>


.bntToAddCart{
    margin-top: 2rem;
    display: flex;
    flex-direction: row-reverse;
}

.alert-p{
	margin: 0;
	/* font-size: 1rem; */
	font-weight: 500;
}
.alertas{
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    flex-direction: column;
    margin: 0;
}
.alert-msg{		
    /* max-width: 920px; */
    /* width: 400px; */
    
    /* text-align: center; */
    color: #212121;
    /* // padding: .5rem 1rem; */
    font-size: .95rem;		
}

.benefit-info p {
    margin: 0;
}

</style>