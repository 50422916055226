<template>
    <div v-if="benefitsFirstGroup.length > 0">  
        <div class="container-tables">
            <div class="column-table">
                <h6 class="subtitle font-weight-bolder mb-2">{{ $t("Benefits") }} {{  yearsBenefitsList[0] }}</h6>
                <div class="table-responsive">
                    <table class="table table-sm">
                        <thead>
                            <tr>
                                <th>{{$t('benefit name')}}</th>												
                                <th class="centerT">{{ $t('Balance') }} </th>
                                <th class="centerT">{{ $t('Quantity to authorize') }} </th>
                            </tr>
                        </thead>
                        
                        <tbody>																                         
                            <tr v-for="(benefit, index) in benefitsFirstGroup" :key="index">

                                <td>
                                    {{ nameBenefit( benefit ) }}
                                </td>
                            
                                <td class="centered-cell">
                                    {{ benefit.balance }}
                                </td>
                                <td class="centered-cell">
                                    <ValidationProvider :name="$t('Quantity')" :rules="{ max_balance: benefit.balance }">
                                        <div slot-scope="{ errors }">
                                            <b-form-input			
                                                size="sm"
                                                type="number"
                                                :placeholder="$t('Quantity')"				
                                                v-model="benefit.qty"     
                                                :state="errors.length > 0 ? false : null"
                                                @input="setQtyBenefit(benefit)" 
                                                style="max-width: 150px;"
                                            />
                                            <b-form-invalid-feedback class="invalid-feedback-custom">{{errors[0]}}</b-form-invalid-feedback>
                                        </div>
                                    </ValidationProvider>
                                </td>
                            </tr>								
                        </tbody>										
                    </table>	
                </div>
            </div>
            <div class="column-table" v-if="benefitsSecondGroup.length > 0">
                <h6 class="subtitle font-weight-bolder mb-2">{{ $t("Benefits") }} {{  yearsBenefitsList[1] }}</h6>
                <div class="table-responsive">
                    <table class="table table-sm">
                        <thead>
                            <tr>
                                <th>{{$t('benefit name')}}</th>												
                                <th class="centerT">{{ $t('Balance') }} </th>
                                <th class="centerT">{{ $t('Quantity to authorize') }} </th>
                            </tr>
                        </thead>
                        
                        <tbody>																                          
                            <tr v-for="(benefit, index) in benefitsSecondGroup" :key="index">
                                <td>
                                    {{ nameBenefit( benefit ) }}
                                </td>
                            
                                <td class="centered-cell">
                                    {{ benefit.balance }}
                                </td>
                                <td class="centered-cell">                                   	
                                    <ValidationProvider :name="$t('Quantity')" :rules="{ max_balance: benefit.balance }">
                                        <div slot-scope="{ errors }">
                                            <b-form-input			
                                                size="sm"
                                                type="number"
                                                :placeholder="$t('Quantity')"				
                                                v-model="benefit.qty"     
                                                :state="errors.length > 0 ? false : null"
                                                @input="setQtyBenefit(benefit)" 
                                                style="max-width: 150px;"
                                            />
                                            <b-form-invalid-feedback class="invalid-feedback-custom">{{errors[0]}}</b-form-invalid-feedback>
                                        </div>
                                    </ValidationProvider>
                                </td>
                            </tr>								
                        </tbody>										
                    </table>	
                </div>                
            </div>
        </div>      
        <b-row>
            <b-col md="6" >
                
            </b-col> 
            <b-col md="6" v-if="benefitsSecondGroup.length > 0">
                
            </b-col>            
        </b-row>
 
	</div>
</template>

<script>
import { extend } from 'vee-validate'
import { mapState, mapActions, mapMutations , mapGetters } from "vuex"

export default {
	props: {			     
        isEditing:{
            type: Boolean,
            required: true
        },
        
	},
    created(){
        extend('max_balance', {
            params: ['balance'],
            validate(value, { balance }) {
                return value <= balance;
            },            
            message: (fieldName, { balance }) => `${this.$t('the qty cannot be greater than balance',{field: fieldName, balance: balance })}`
        })
    },
    data() {
		return {	
		}
	},
    computed: {
		...mapState('auth', ['user']),	
		...mapState('owners', ['yearsBenefitsList','benefitsToUseInBooking']),	
        ...mapGetters('owners', ['benefitsFirstGroup', 'benefitsSecondGroup' ]),        	        
      
	},
	methods: {
		...mapActions('owners', ['saveBenefitsBookingOwner', 'updateBenefitsBookingOwner']),
        ...mapMutations('owners',['setQtyBenefitToUseInBooking']),		
        setQtyBenefit( data ){
            const { transaction, qty } = data
            this.setQtyBenefitToUseInBooking({transaction, qty })
        },
        nameBenefit( benefit ){
            const idLangUser = this.user.languageId            
            return idLangUser === 1 ? benefit.benefitnameEng : benefit.benefitname
        }
	},
}
</script>
<style scoped>
   .centered-cell, th.centerT {
        text-align: center; /* Centrado horizontal */
        vertical-align: middle; /* Centrado vertical */
    }

    td input {
        margin: 0 auto;
        display: block;
    }

    .container-tables {
        display: flex;
        flex-direction: column;
        gap: 1rem;         
    }

    .column-table{
        max-width: 700px;
        width: 100%;        
    }
    
    @media(min-width: 1080px) {
        .container-tables {
            flex-direction: row;
            gap: 7rem; 
        }
        .column-table{
            flex: 1;
        }
    }
</style>